import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";

import "./firstComponentSmall.scss";

const FirstComponentSmall = ({
  image,
  title,
  description,
  subtitle,
  primary_color,
  secondary_color,
}) => {
  return (
    <div
      className="header"
      style={
        image
          ? {
              backgroundImage: `url(${image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
          : primary_color && secondary_color
          ? {
              background: `radial-gradient(92.24% 138.22% at 87.41% 109.78%, ${primary_color} 0%, ${secondary_color} 100%)`,
            }
          : {}
      }
    >
      <LayoutComponent>
        <div className="body-content">
          { title && <h1 className="title center">{title}</h1>}
          { description && <p className="description center">{description}</p>}
          { subtitle && (
            <div className="subtitle center">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={subtitle}
              />
            </div>
          )}
        </div>
      </LayoutComponent>
    </div>
  );
};

export default FirstComponentSmall;
