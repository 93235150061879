import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponentSmall from "../../components/FirstComponentSmall/FirstComponentSmall";
import MitKinalACMO from "../../components/MitKinalACMO/MitKinalACMO";
import DownloadNow from "../../components/DownloadNow/DownloadNow";
import "./blog.scss";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";

const Blog = (props) => {
  const {
    content,
    mitKinalACMO,
    vallalkozniCsodas: { vallalkozniCsodas },
  } = props.pageContext;


  const location_href = props.location.pathname;

  return (
    <div className="blog-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#8C17FF"}
        SEO_title={"CMO - Blog 👉️ A legfrissebb hírek a kisvállalkozói világból"}
        SEO_description={"CMO - Blog 🥇 Naprakész blogcikkek vállalkozóknak. Ismerd meg a legfrissebb jogszabályokat és a legújabb trendeket a blogunkon! ✔️"}
        SEO_keywords={"CMO, blog"}
        SEO_location={location_href}
      >
        <FirstComponentSmall
          primary_color={"#5107FF"}
          secondary_color={"#8C17FF"}
          title={"Blog cikkeink"}
          description={"Naprakész cikkek a kisvállalkozói világból"}
        />
        <div className="blog-body-content-wrapper">
          <LayoutComponent>
            {content.map((cont, index) => {
              return (
                <div className="blog-body-content" key={index}>
                  <div className="articles">
                    {cont.blogposts
                      .sort(
                        (post1, post2) =>
                          new Date(post2.published_at).valueOf() -
                          new Date(post1.published_at).valueOf()
                      )
                      .map((post, index) => {
                        return (
                          <a
                            href={`/blog${post.slug}`}
                            className="article"
                            key={index}
                          >
                            {post.thumbnail && (
                              <img
                                src={`${process.env.GATSBY_CMS_URL}${post.thumbnail.url}`}
                                alt={post.thumbnail_alt}
                                loading="lazy"
                              />
                            )}
                            <div className="date">
                              {new Date(post.createdAt).toLocaleDateString(
                                "hu-HU"
                              )}
                            </div>
                            <div className="reading_time">
                              Olvasási idő: {post.reading_time}
                            </div>
                            <div className="article_title">{post.title}</div>
                            <div className="article_desc">
                              {post.short_description}
                            </div>
                          </a>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </LayoutComponent>
        </div>
        <MitKinalACMO
          title={mitKinalACMO.title}
          cards={mitKinalACMO.cards}
          description={mitKinalACMO.description}
          wrapper_style_overrides={{"marginTop": "0px"}}
        />
        <DownloadNow
          content={{
            items: vallalkozniCsodas.vallakozniCsodasItem,
            primary_color: vallalkozniCsodas.primary_color,
            secondary_color: vallalkozniCsodas.secondary_color,
          }}
        />
      </LayoutHeaderFooter>
    </div>
  );
};

export default Blog;
