import React from "react";
import LayoutComponent from "../LayoutComponent/LayoutComponent";

import Button from "../Button/Button";

import "./downloadNow.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const DownloadNow = ({ content, onClick }) => {
  const {
    primary_color,
    secondary_color,
    title,
    items,
    description,
    button_text,
    hover,
    button_color,
  } = content;

  return (
    <div className="downloadNow-wrapper">
      <LayoutComponent
        background={`linear-gradient(30deg, ${primary_color} 30%, ${secondary_color} 55%, ${primary_color} 65%)`}
      >
        <div className="body-content">
          <section className="content">
            <h2>{title}</h2>
            {description && (
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={description}
                />
              </div>
            )}
            {items && (
              <ul>
                {items.map((item, index) => {
                  return (
                    <li key={index}>
                      <img
                        src={`${process.env.GATSBY_CMS_URL}${item.logo.url}`}
                        alt="cmo_ikon"
                        loading="lazy"
                      />
                      <div>{item.desc}</div>
                    </li>
                  );
                })}
              </ul>
            )}
          </section>
          <section className="button-wrapper">
            {button_text && (
              <Button
                variant={"outlined"}
                backgroundColor={"transparent"}
                hover={hover}
                color={button_color ? button_color : "white"}
                text={button_text}
                onClick={onClick}
              />
            )}
          </section>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default DownloadNow;
