import React from "react";
import ReactMarkDown from "react-markdown";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./mitKinalACMO.scss";
import CMOButton from "../Button/Button";
import rehypeRaw from "rehype-raw";

const MitKinalACMO = ({
  description,
  cards,
  wrapper_style_overrides,
  background_color,
  primary_color,
  secondary_color,
}) => {
  return (
    <div className="mitKinalACMO-wrapper" style={wrapper_style_overrides}>
      <LayoutComponent
        background_color={background_color}
        background={`radial-gradient(47.47% 47.47% at 45.43% 52.53%, ${secondary_color} 0%, ${primary_color} 100%)`}
      >
        <div className="body-content">
          <div className="description">
            <ReactMarkDown
              rehypePlugins={[rehypeRaw]}
              children={description}
            />
          </div>
          <div
            className="firstComponent-cards"
            style={
              cards.length === 2
                ? {
                    gridTemplateColumns: "calc(1200px / 4) calc(1200px / 4)",
                    justifyContent: "center",
                  }
                : null
            }
          >
            {cards.map((card, index) => {
              return (
                <a
                  href={card.url}
                  key={index}
                  className={"firstComponent-card"}
                  style={{
                    background: `linear-gradient(119.64deg, ${card.primary_color} 31.87%, ${card.secondary_color} 96.02%)`,
                  }}
                >
                  <div className={"firstComponent-card-content"}>
                    <p className="card-title">{card.title}</p>
                    <p className="card-description">{card.description}</p>
                    <CMOButton
                      variant="contained"
                      background_color={"#5107ff"}
                      text={card.button_text}
                      color={"white"}
                    ></CMOButton>
                    <div
                      className="image-wrapper"
                      aria-label="cmo_logo"
                      style={{
                        backgroundImage: `url(${
                          process.env.GATSBY_CMS_URL + card.logo.url
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};
export default MitKinalACMO;
